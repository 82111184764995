import { configurationsStoreGetters, kpatlasStoreActions } from "@/store";
import { formatNumber } from "@/filters";
import { aidsInfoService, jhuRedcupService } from "@/services";
import { Deferred } from "@/services/_base/Deferred";

export class Utils {
    public static MakeLegenda(param: KpAtlas.BlockParameter, data: KpAtlas.Response[], colorClassification: number[][], breaks: number[] = [], selectValueFunction: (d: KpAtlas.Response) => number): KpAtlas.LegendClassifications[] {
        const result: KpAtlas.LegendClassifications[] = [];
        const step = Math.ceil(data.length / 4);

        for (let i = 0; i < 4; i++) {
            let valueBreak: number = null;
            if (breaks.length > 0) {
                valueBreak = breaks[i];
                const item = {
                    color: colorClassification[i],
                    min: i === 0 ? 0 : Number((result[i - 1].max + 0.0001).toFixed(1)),
                    max: i < 3 ? Number(valueBreak.toFixed(1)) : data.length > 0 && data[(data.length - 1)] ? Number((selectValueFunction(data[(data.length - 1)])).toFixed(1)) : 100,
                } as KpAtlas.LegendClassifications;
                item.value = (i === 0 ? `≤ ${formatNumber(item.max, 1)}${this.GetUnitMisure(param)}` : i === 3 ? `≥ ${formatNumber(item.min, 1)}${this.GetUnitMisure(param)}` : `${formatNumber(item.min, 1)}${this.GetUnitMisure(param)} - ${formatNumber(item.max, 1)}${this.GetUnitMisure(param)}`);
                result.push(item);
            } else {
                result.push({ color: null, min: null, max: null });
            }
        }

        if (breaks.length <= 0) {
            for (let j = 0; j < data.length; j++) {
                const idx = Math.floor(j / step);
                result[idx].color = colorClassification[idx];
                result[idx].min = idx === 0 ? 0 : Number((result[idx - 1].max + 0.0001).toFixed(1));
                result[idx].max = j !== (data.length - 1 || data.length === 1) ?
                    Number((selectValueFunction(data[(j)])).toFixed(1)) :
                    Number((selectValueFunction(data[(data.length - 1)]) * 1000).toFixed(1)); // Moltiplico per 1000 per aumentare la soglia massima
                result[idx].value = (idx === 0 ? `≤ ${formatNumber(result[idx].max, 1)}${this.GetUnitMisure(param)}` : idx === 3 ? `> ${formatNumber(result[idx].min, 1)}${this.GetUnitMisure(param)}` : `> ${formatNumber(result[idx].min, 1)}${this.GetUnitMisure(param)} - ${formatNumber(result[idx].max, 1)}${this.GetUnitMisure(param)}`);
            }
        }

        return result;
    }

    public static RecalcLegendaStaticCoded(data, selectValueFunction: (d: KpAtlas.Response) => number) {
        const config = configurationsStoreGetters.config();
        const resultMap = new Map<number, KpAtlas.LegendClassifications>();
        for (let j = 0; j < data.length; j++) {
            const elem = data[j];
            const key = selectValueFunction(elem);
            if (!resultMap.has(key)) {
                const decoded = config.legend.decodeDefinition[`${key}`];
                if (decoded) {
                    resultMap.set(key, {
                        color: decoded.color.split(",").map((m) => parseInt(m, 10)),
                        min: key,
                        max: key,
                        value: decoded.value,
                    });
                } else {
                    resultMap.set(key, {
                        color: [70, 70, 70],
                        min: key,
                        max: key,
                        value: elem.denominator,
                    });
                }
            }
        }

        const result = Array.from(resultMap.values());
        kpatlasStoreActions.setLegend(result);
    }

    public static CleanLegenda() {
        const result = [];
        for (let i = 0; i < 4; i++) {
            result.push({ color: null, min: null, max: null });
        }
        kpatlasStoreActions.setLegend(result);
    }

    public static ParamReduce(data: KpAtlas.ResponseIndicatorBlockParam[]): KpAtlas.ResponseIndicatorBlock[] {
        const temp = {};
        const reduced = data.filter((f) => !!f).reduce((r, o) => {
            let key = `${o.indicator.key}`;
            if (o.block) {
                key += `_${o.block.label.replace(/ /gi, "-").toLowerCase()}`;
            }
            if (!temp[key]) {
                temp[key] = { indicator: o.indicator, block: o.block, groups: [o] } as KpAtlas.ResponseIndicatorBlock;
                r.push(temp[key]);
            } else {
                temp[key].groups.push(o);
            }
            return r;
        }, [] as KpAtlas.ResponseIndicatorBlock[]);
        return reduced;
    }

    public static ObservationsReduce(data: AidsInfoCall.Observation[], block?: KpAtlas.Block, param?: KpAtlas.BlockParameter): KpAtlas.Response[] {
        const temp = {};
        const reduced = data.sort((a, b) => {
            if (a.TIME_PERIOD > b.TIME_PERIOD) { return -1; }
            if (a.TIME_PERIOD < b.TIME_PERIOD) { return 1; }
            return 0;
        }).reduce((r, o) => {
            const key = `${o.AREA.id}`;
            if (!temp[key]) {
                const parsed = Utils.NormalizeObservation(o, param);
                temp[key] = {
                    iso3: key,
                    indicatorKey: parsed.INDICATOR.id,
                    indicatorName: this.capitalizeFirstLetter(parsed.INDICATOR.value),
                    blockLabel: block ? block.label : null,
                    paramKey: parsed.SUBGROUP.id,
                    paramName: this.capitalizeFirstLetter(parsed.SUBGROUP.value),
                    lastValue: parsed.VALUE,
                    timePeriod: parsed.TIME_PERIOD,
                    hasSubNationalData: block ? block.hasSubNationalData : false,
                    nationWithSubNationalData: block ? block.nationWithSubNationalData : [],
                    denominator: parsed.DENOMINATOR,
                    unit: parsed.UNIT.id,
                    footNotes: parsed.FOOTNOTES,
                    isTextualData: parsed.IS_TEXTUALDATA,
                    observations: [parsed],
                } as KpAtlas.Response;

                r.push(temp[key]);
            } else {
                temp[key].observations.push(Utils.NormalizeObservation(o, param));
            }
            return r;
        }, [] as KpAtlas.Response[]);
        return reduced;
    }

    public static NormalizeObservation(observation: AidsInfoCall.Observation, param: KpAtlas.BlockParameter): KpAtlas.NormalizeObservation {
        return {
            INDICATOR: observation.INDICATOR,
            SUBGROUP: observation.SUBGROUP,
            AREA: observation.AREA,
            UNIT: observation.UNIT,
            TIME_PERIOD: parseInt(observation.TIME_PERIOD, 10),
            FOOTNOTES: observation.FOOTNOTES,
            IS_TEXTUALDATA: JSON.parse((observation.IS_TEXTUALDATA || "false").toLowerCase()),
            DENOMINATOR: JSON.parse((observation.IS_TEXTUALDATA || "false").toLowerCase()) ? observation[param.field] : observation.DENOMINATOR,
            VALUE: JSON.parse((observation.IS_TEXTUALDATA || "false").toLowerCase()) ? observation.DENOMINATOR : observation[param.field],
        };
    }

    public static GetUnitMisure(obj: KpAtlas.Block | KpAtlas.BlockParameter) {
        const measureTypes = configurationsStoreGetters.config().measureTypes;
        return (measureTypes[obj.unit] || { suffix: "" }).suffix;
    }

    public static capitalizeFirstLetter(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    public static async getIndicatorData(indicator: KpAtlas.Indicator | JHU.Indicator,
        blocks: Array<KpAtlas.Block | JHU.Block>,
        areas: Array<string>,
        recalcLegend: boolean = false): Promise<KpAtlas.ResponseIndicatorBlock[]> {

        switch (indicator.sourceData) {
            case "KPATLAS":
                return await aidsInfoService.CountriesDatas(indicator as KpAtlas.Indicator, blocks as Array<KpAtlas.Block>, areas, recalcLegend)
            case "JHU":
                return await jhuRedcupService.CountriesDatas(indicator as JHU.Indicator, blocks as Array<JHU.Block>, areas, recalcLegend);
            default: {
                var d = new Deferred<KpAtlas.ResponseIndicatorBlock[]>();
                d.resolve([]);
                return d.promise;
            }
        }
    }
}
