import Vue from "vue";
import Component from "vue-class-component";
import { Esri } from "./esriMap";

@Component({})
export default class App extends Vue {


  async created(){
    ((await Esri.config()) as any).apiKey = "AAPK8c99d8779c094c6ba70d7db3e38f332aFTTK4iz-l7f_5f4Q4HZzLZyiCqigwX3olqRxTlqQSE15NC7STVvGcGdY1oEZTRmB";
  }
}