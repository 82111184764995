import { storageFactory } from "./app.storageFactory";

export class UnaidsLocalStorage {
    private _db: Storage;
    constructor() {
        this._db = storageFactory(localStorage);
    }

    public getItem<T>(key: string): T {
        return JSON.parse(this._db.getItem(key));
    }

    public setItem<T>(key: string, data: T): void {
        this._db.setItem(key, JSON.stringify(data));
    }

    public removeItem(key: string): void {
        this._db.removeItem(key);
    }

    public clearAll(): void {
        this._db.clear();
    }
}
export const unaidsLocalStorage = new UnaidsLocalStorage();
