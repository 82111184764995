
import { baseRestService } from "./_base";
import { Config } from "@/config";
import { configurationsStoreActions } from "@/store";
import { Deferred } from "./_base/Deferred";
import { Esri } from "@/esriMap";

class ConfigurationService extends baseRestService {

  constructor() {
    super();
    this.baseUrl = Config.clientSideUrl;
  }

  public async LoadConfiguration(): Promise<KpAtlas.Configurations> {
    const result = await this.Get<KpAtlas.Configurations>(`assets/configurations/UNAIDS/configuration.json`);
    configurationsStoreActions.setConfiguration(result);

    ((await Esri.config()) as any).apiKey = result.arcgisApiKey;
    return result;
  }

  public async LoadAnnex1(): Promise<KpAtlas.IndicatorGroup[]> {
    const result = await this.Get<KpAtlas.IndicatorGroup[]>(`assets/configurations/UNAIDS/annex1.json`);
    configurationsStoreActions.setAnnex1(result);
    // this.extractCountriesConfiguration(result);
    // this.extractSubregionConfiguration(result);
    return result;
  }

  public async LoadCountriesPriority(): Promise<KpAtlas.CountriesPriority[]> {
    const result = await this.Get<KpAtlas.CountriesPriority[]>(`assets/configurations/UNAIDS/countriesPriorityMap.json`);
    configurationsStoreActions.setCountriesPriority(result);
    return result;
  }


  // private extractCountriesConfiguration(result: KpAtlas.IndicatorGroup[]) {
  //   const calls = [];
  //   result.forEach(group => {
  //     group.indicators
  //       .filter(f => (f.blocks || []).length > 0 && f.visible === true)
  //       .forEach(indicator => {
  //         const obj = {

  //           title: null,
  //           summary: null,
  //           thumbnail: null,
  //           description: null,
  //           termOfUse: null,
  //           categories: null,
  //           tags: null,
  //           credits: null,
  //           group: `${group.name} (${group.key})`,
  //           indicator: `${indicator.name} (${indicator.key})`,
  //           params: [],
  //           relativeUrl: indicator.relativeUrl,
  //           baseUrl: "https://onlinedb.unaids.org"
  //         };

  //         indicator.blocks.forEach(block => {
  //           obj.params = obj.params.concat(block.params.map(param => {
  //             return {
  //               label: param.label,
  //               key: param.key,
  //               unit: param.unit,
  //               group: param.group,
  //               source: param.source,
  //             };
  //           }))
  //         })

  //         calls.push(obj);
  //       });
  //   });
  //   console.log(JSON.stringify(calls));
  // }

  // private extractSubregionConfiguration(result: KpAtlas.IndicatorGroup[]) {

  //   const calls = result.map(group => {
  //     return {
  //       group: group.name,
  //       indicators: group.indicators
  //         .filter(f => (f.subnationalRegionAndSite || []).length > 0 && f.visible === true)
  //         .map(indicator => {
  //           const obj = {

  //             title: null,
  //             summary: null,
  //             thumbnail: null,
  //             description: null,
  //             termOfUse: null,
  //             categories: null,
  //             tags: null,
  //             credits: null,
  //             group: `${group.name} (${group.key})`,
  //             indicator: `${indicator.name} (${indicator.key})`,
  //             params: (indicator.subnationalRegionAndSite || []).map(m => {
  //               return {
  //                 label: m.label,
  //                 key: m.key,
  //                 unit: m.unit,
  //                 group: m.group,
  //                 number: m.number,
  //                 source: m.source,
  //               }
  //             }),
  //             relativeUrl: indicator.relativeUrl,
  //             baseUrl: "https://onlinedb.unaids.org"
  //           };
  //           return obj;
  //         })
  //     };
  //   });
  //   console.log(JSON.stringify(calls));
  // }

  public async LoadJHUConfiguration(): Promise<JHU.Configuration[]> {
    const result = await this.Get<JHU.Configuration[]>(`assets/configurations/JHU/indicators.json`);
    configurationsStoreActions.setJHUConfigurations(result);
    return result;
  }

  public async LoadJHUCountriesMapper(): Promise<JHU.CountryMapper[]> {
    const result = await this.Get<JHU.CountryMapper[]>(`assets/configurations/JHU/countries_mapper.json`);
    configurationsStoreActions.setJHUCountriesMapper(result);
    return result;
  }
}

export const configurationService = new ConfigurationService();
