import Vue from "vue";
import { store } from "@/store";

var sc = document.createElement("script");
sc.setAttribute("type", "text/javascript");
document.head.appendChild(sc);

import { ServiceInizialization } from "@/app_startup/startup";
// modificate qui se volete attivare i servizi di sviluppo di vue
Vue.config.devtools = true;
Vue.config.performance = false;

// Mi assicuro che durante la pubblicazione i servizi di sviluppo di VUE siano disattivati.
if (!DEVELOPMENT) {
    Vue.config.devtools = false;
    Vue.config.performance = false;
}

// import vgl from 'vue-golden-layout'
// import 'golden-layout/src/css/goldenlayout-light-theme.css'
// Vue.use(vgl)

import { Loader } from "@/esriMap";
Loader.initialize();

import * as directives from "./directives";
const VueDynamicRegister = {
    install: function(Vue) {
        // Register directives
        for (var directive in directives) {
            Vue.directive(directive, directives[directive]);
        }
    },
};
Vue.use(VueDynamicRegister);

import * as filters from './filters' // global filters
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})


import VeeValidate, { Validator } from "vee-validate";
import messagesEn from "vee-validate/dist/locale/en";
Validator.localize("en", messagesEn);

import Highcharts from "highcharts";
import VueHighcharts from "vue2-highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import SolidGauge from "highcharts/modules/solid-gauge";


HighchartsMore(Highcharts);
SolidGauge(Highcharts);

Vue.use(VueHighcharts);

import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)

Vue.use(VeeValidate, {
    locale: "en",
    fieldsBagName: "vfields",
    validity: true,
    classes: true,
    classNames: {
        valid: "is-valid",
        invalid: "is-invalid",
    },
    dictionary: {
        en: {
            messages: messagesEn,
        }
    },
});

import resize from "vue-resize-directive";
Vue.directive("resize", resize);

import isvalid from "./mixins/isValid";
Vue.mixin(isvalid);

import router from "./router";

import App from "./App.vue";
export var KPAtlasApp = null;
ServiceInizialization().then(result => {
    setTimeout(() => {
        KPAtlasApp = new Vue({
            el: "#app",
            router,
            store,
            template: "<app/>",
            components: {
                App,
            },
        });
    }, 1000);
});