// layout
// import Full from "@/layouts/Full.vue";
const Full = () =>
    import ( /* webpackChunkName: "layout" */ "@/layouts/Full.vue");

// Base views
const Dashboard = () =>
    import ( /* webpackChunkName: "dashboard" */ "@/app_modules/dashboard/Dashboard.vue");

function injectRoutes(mainRoute, childroutes) {
    for (const key in childroutes) {
        const element = childroutes[key];
        mainRoute[0].children.push(element);
    }
}

const routes = [{
    path: "/",
    redirect: "/dashboard",
    name: "Home",
    meta: {
        basename: 'dashboard',
    },
    component: Full,
    children: [{
        path: "dashboard",
        name: "dashboard",
        meta: {
            basename: 'dashboard',
        },
        component: Dashboard
    }]
}];

// import { route as fieldRoutes } from "@/app_modules/fields_management/routes";
// injectRoutes(routes, fieldRoutes);

// import { routes as boundariesRoute } from "@/app_modules/planner/routes";
// injectRoutes(routes, boundariesRoute);

export default routes;