
import { baseRestService } from "./_base";
import { geometriesStoreActions, getStoreState } from "@/store";
import { Config } from "@/config";
import { unaidsPersistStorage } from "@/store/app.persistStorage";
import { unaidsLocalStorage } from "@/store/app.localStorage";

class GeometiesService extends baseRestService {

  constructor() {
    super();
    this.baseUrl = Config.geoemtriesServerUrl;
  }

  public async Countries(where: string = "FULL_NAME <> 'NO_LABEL'", returnGeometry: boolean = true, maxAllowableOffset: number = 0, outFields: string = "OBJECTID,ISO3,FULL_NAME", orderByFields: string = "ISO3"): Promise<Array<IFeatures<Countries>>> {
    //// const stored = await unaidsPersistStorage.getItems<IFeatures<Countries>>("countries");
    const stored = await unaidsLocalStorage.getItem<Array<IFeatures<Countries>>>("countries");
    if (stored && stored.length > 0) {
      getStoreState().geometries.countries = stored;
      return stored;
    }

    const params = { where, returnGeometry, maxAllowableOffset, outFields, orderByFields };
    const result = await this.Get<any>(`2/query?f=json`, params);
    if (result) {
      geometriesStoreActions.setCountries(result.features || []);
      return result.features || [];
    }
    return [];
  }

  public async Regions(ISO3: string, returnGeometry: boolean = true, maxAllowableOffset: number = 0, outFields: string = "OBJECTID,ISO3,NAME,Area_ID", orderByFields: string = "Area_ID"): Promise<Array<IFeatures<Regions>>> {
    if (!ISO3) {
      console.log("ISO3 is required to get regions");
      return [];
    }

    // const stored = await unaidsLocalStorage.getItem<Array<IFeatures<Regions>>>(`regions_${ISO3}`);
    // if (stored && stored.length > 0) {
    //   getStoreState().geometries.regions = stored;
    //   return stored;
    // }

    const where: string = `Area_ID is not null AND ISO3 = '${ISO3}'`;
    const params = { where, returnGeometry, maxAllowableOffset, outFields, orderByFields };
    const result = await this.Get<any>(`1/query?f=json`, params);
    if (result) {
      const features = result.features || [];
      geometriesStoreActions.setRegions({ ISO3, features });
      return features;
    }
    return [];
  }

  public async Sites(ISO3: string, returnGeometry: boolean = true, outFields: string = "OBJECTID,ISO3,NAME,Area_ID", orderByFields: string = "Area_ID"): Promise<Array<IFeatures<Sites>>> {
    if (!ISO3) {
      console.log("ISO3 is required to get regions");
      return [];
    }

    // const stored = await unaidsLocalStorage.getItem<Array<IFeatures<Sites>>>(`sites_${ISO3}`);
    // if (stored && stored.length > 0) {
    //   getStoreState().geometries.sites = stored;
    //   return stored;
    // }

    const where: string = `Area_ID is not null AND ISO3 = '${ISO3}'`;
    const params = { where, returnGeometry, outFields, orderByFields };
    const result = await this.Get<any>(`0/query?f=json`, params);
    if (result) {
      const features = result.features || [];
      geometriesStoreActions.setSites({ ISO3, features });
      return features;
    }
    return [];
  }
}

export const geometriesService = new GeometiesService();
