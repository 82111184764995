export function storageFactory(storage: Storage): Storage {
    const length = 0;

    function clear(): void {
        storage.clear();
    }

    function getItem(name: string): string | null {
        return storage.getItem(name);
    }

    function key(index: number): string | null {
        return storage.key(index);
    }

    function removeItem(name: string): void {
        storage.removeItem(name);
    }

    function setItem(name: string, value: string): void {
        try {
            storage.setItem(name, value);
        } catch (e) {
            console.error("Error when saving on storage");
            const storageSize = Math.round(JSON.stringify(storage).length / 1024);
            console.log(`LIMIT REACHED: ${storageSize}K`, e);
        }
    }

    return {
        getItem,
        setItem,
        removeItem,
        clear,
        key,
        length,
    };
}
