import { CreateActionsHandler, CreateGettersHandler, fillArray } from "./UTILS";

export interface KpatlasStoreModel {
  showSubnationalData: boolean;
  legend: KpAtlas.LegendClassifications[];
}

interface KpatlasStoreGetters {
  showSubnationalData(): boolean;
  legend(): KpAtlas.LegendClassifications[];
}
interface KpatlasStoreActions {
  setShowSubnationalData(data: boolean);
  setLegend(data: KpAtlas.LegendClassifications[]);
  cleanLegend();
}

export const kpatlasStore = {
  PREFIX: "kpatlas",
  namespaced: true,
  state: {
    showSubnationalData: false,
    legend: [],
  } as KpatlasStoreModel,
  getters: {
    showSubnationalData: (state: KpatlasStoreModel) => (): boolean => {
      return state.showSubnationalData;
    },
    legend: (state: KpatlasStoreModel) => (): KpAtlas.LegendClassifications[] => {
      return state.legend;
    },
  },
  mutations: {
    setShowSubnationalData(state: KpatlasStoreModel, data: boolean) {
      state.showSubnationalData = data;
    },
    setLegend(state: KpatlasStoreModel, data: KpAtlas.LegendClassifications[]) {
      state.legend = state.legend.splice(0, state.legend.length);
      if (!data || data.length <= 0) { return; }
      fillArray(state.legend, data);
    },
    cleanLegend(state: KpatlasStoreModel) {
      state.legend = state.legend.splice(0, state.legend.length);
    },
  },
  actions: {
    setShowSubnationalData(context, data: boolean) {
      context.commit("setShowSubnationalData", data);
    },
    setLegend(context, data: Array<IFeatures<Countries>>) {
      context.commit("setLegend", data);
    },
    cleanLegend(context) {
      context.commit("cleanLegend");
    },
  },
};

export const kpatlasStoreActions = CreateActionsHandler<KpatlasStoreActions>(kpatlasStore);
export const kpatlasStoreGetters = CreateGettersHandler<KpatlasStoreGetters>(kpatlasStore);
