import { CreateActionsHandler, CreateGettersHandler } from "./UTILS";
import { unaidsLocalStorage } from "./app.localStorage";
import { unaidsPersistStorage } from "./app.persistStorage";

export interface GeometriesStoreModel {
  countries: Array<IFeatures<Countries>>;
  selectedCountry: __esri.Graphic;
  regions: Array<IFeatures<Regions>>;
  selectedRegion: __esri.Graphic;
  sites: Array<IFeatures<Sites>>;
  selectedSite: __esri.Graphic;
}

interface GeometriesStoreGetters {
  getCountries(ISO3?: string): Array<IFeatures<Countries>>;
  getSelectedCountry(): __esri.Graphic;
  getRegions(ISO3: string): Array<IFeatures<Regions>>;
  getSelectedRegion(): __esri.Graphic;
  getSites(ISO3: string): Array<IFeatures<Sites>>;
  getSelectedSite(): __esri.Graphic;
}
interface GeometriesStoreActions {
  setCountries(data: Array<IFeatures<Countries>>);
  cleanCountries();
  setSelectedCountry(data: __esri.Graphic);
  cleanSelectedCountry();
  setRegions(data: {ISO3: string, features: Array<IFeatures<Regions>>});
  cleanRegions();
  setSelectedRegion(data: __esri.Graphic);
  cleanSelectedRegion();
  setSites(data: {ISO3: string, features: Array<IFeatures<Sites>>});
  cleanSites();
  setSelectedSite(data: __esri.Graphic);
  cleanSelectedSite();
}

export const geometriesStore = {
  PREFIX: "geometries",
  namespaced: true,
  state: {
    countries: [],
    selectedCountry: null,
    regions: [],
    selectedRegion: null,
    sites: [],
    selectedSite: null,
  } as GeometriesStoreModel,
  getters: {
    getCountries: (state: GeometriesStoreModel) => (ISO3?: string) => {
      return state.countries.filter((f) => !ISO3 || f.attributes.ISO3 === ISO3);
    },
    getSelectedCountry: (state: GeometriesStoreModel) => () => {
      return state.selectedCountry;
    },
    getRegions: (state: GeometriesStoreModel) => (ISO3: string) => {
      return state.regions.filter((f) => f.attributes.ISO3 === ISO3);
    },
    getSelectedRegion: (state: GeometriesStoreModel) => () => {
      return state.selectedRegion;
    },
    getSites: (state: GeometriesStoreModel) => (ISO3: string) => {
      return state.sites.filter((f) => f.attributes.ISO3 === ISO3);
    },
    getSelectedSite: (state: GeometriesStoreModel) => () => {
      return state.selectedSite;
    },
  },
  mutations: {
    setCountries(state: GeometriesStoreModel, data: Array<IFeatures<Countries>>) {
      if (!data || data.length <= 0) { return; }
      const objectIds = state.countries.map((m) => m.attributes.OBJECTID);
      data.forEach((i) => {
        if (objectIds.indexOf(i.attributes.OBJECTID)) {
          state.countries.push(i);
          // unaidsPersistStorage.setItem("countries", i);
        }
      });
      unaidsLocalStorage.setItem("countries", data);
    },
    cleanCountries(state: GeometriesStoreModel) {
      state.countries = state.countries.splice(0, state.countries.length);
    },
    setSelectedCountry(state: GeometriesStoreModel, data: __esri.Graphic) {
      state.selectedCountry = data;
    },
    cleanSelectedCountry(state: GeometriesStoreModel) {
      state.selectedCountry = null;
    },
    setRegions(state: GeometriesStoreModel, data: {ISO3: string, features: Array<IFeatures<Regions>>}) {
      if (!data || data.features.length <= 0) { return; }
      state.selectedRegion = null;
      data.features.filter((x) => !!x).forEach((i) => {
        state.regions.push(i);
      });
      // unaidsLocalStorage.setItem(`regions_${data.ISO3}`, data.features);
    },
    cleanRegions(state: GeometriesStoreModel) {
      state.regions = state.regions.splice(0, state.regions.length);
    },
    setSelectedRegion(state: GeometriesStoreModel, data: __esri.Graphic) {
      state.selectedRegion = data;
    },
    cleanSelectedRegion(state: GeometriesStoreModel) {
      state.selectedRegion = null;
    },
    setSites(state: GeometriesStoreModel, data: {ISO3: string, features: Array<IFeatures<Sites>>}) {
      if (!data || data.features.length <= 0) { return; }
      state.selectedSite = null;
      data.features.filter((x) => !!x).forEach((i) => {
        state.sites.push(i);
      });
      // unaidsLocalStorage.setItem(`sites_${data.ISO3}`, data.features);
    },
    cleanSites(state: GeometriesStoreModel) {
      state.sites = state.sites.splice(0, state.sites.length);
    },
    setSelectedSite(state: GeometriesStoreModel, data: __esri.Graphic) {
      state.selectedSite = data;
    },
    cleanSelectedSite(state: GeometriesStoreModel) {
      state.selectedSite = null;
    },
  },
  actions: {
    setCountries(context, data: Array<IFeatures<Countries>>) {
      context.commit("setCountries", data);
    },
    cleanCountries(context) {
      context.commit("cleanCountries");
    },
    setSelectedCountry(context, data: __esri.Graphic) {
      context.commit("setSelectedCountry", data);
    },
    cleanSelectedCountry(context) {
      context.commit("cleanSelectedCountry");
    },
    setRegions(context, data: {ISO3: string, features: Array<IFeatures<Regions>>}) {
      context.commit("setRegions", data);
    },
    cleanRegions(context) {
      context.commit("cleanRegions");
    },
    setSelectedRegion(context, data: __esri.Graphic) {
      context.commit("setSelectedRegion", data);
    },
    cleanSelectedRegion(context) {
      context.commit("cleanSelectedRegion");
    },
    setSites(context, data: {ISO3: string, features: Array<IFeatures<Sites>>}) {
      context.commit("setSites", data);
    },
    cleanSites(context) {
      context.commit("cleanSites");
    },
    setSelectedSite(context, data: __esri.Graphic) {
      context.commit("setSelectedSite", data);
    },
    cleanSelectedSite(context) {
      context.commit("cleanSelectedSite");
    },
  },
};

export const geometriesStoreActions = CreateActionsHandler<GeometriesStoreActions>(geometriesStore);
export const geometriesStoreGetters = CreateGettersHandler<GeometriesStoreGetters>(geometriesStore);
