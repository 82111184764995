import Axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from "axios";
import { FactoryService as Factory } from "@/services/_base/factoryService";
import Qs from "qs";
import { unjoin } from "@/store/UTILS";
import { AlertHelper } from "./AlertHelper";
import { content } from "html2canvas/dist/types/css/property-descriptors/content";
import QueryString from "qs";
import { Deferred } from "./Deferred";
import { configurationsStoreGetters } from "@/store";

type InterceptorRequestDelegate = (config: AxiosRequestConfig) => AxiosRequestConfig;
type InterceptorResponseDelegate = (config: AxiosResponse) => AxiosResponse;
type InterceptorErrorDelegate = (config: any) => any;
type RequestInterceptorChangedDelegate = (item: InterceptorRequestDelegate) => void;
type ResponseInterceptorChangedDelegate = (item: InterceptorResponseDelegate) => void;
type ErrorInterceptorChangedDelegate = (item: InterceptorErrorDelegate) => void;

export class interceptorsConfig {
  public requestInterceptors: InterceptorRequestDelegate[] = [];
  public responseInterceptors: InterceptorResponseDelegate[] = [];
  public errorsInterceptors: InterceptorErrorDelegate[] = [];
}

export class sharedInterceptors extends interceptorsConfig {
  public addRequestInterceptor(item: InterceptorRequestDelegate) {
    this.requestInterceptors.push(item);

    if (this.onRequestInterceptorChanged) { this.onRequestInterceptorChanged(item); }
  }

  public addResponseInterceptor(item: InterceptorResponseDelegate) {
    this.responseInterceptors.push(item);

    if (this.onResponseInterceptorChanged) { this.onResponseInterceptorChanged(item); }
  }

  public addErrorInterceptor(item: InterceptorErrorDelegate) {
    this.errorsInterceptors.push(item);

    if (this.onErrorInterceptorChanged) { this.onErrorInterceptorChanged(item); }
  }

  public onRequestInterceptorChanged: RequestInterceptorChangedDelegate;
  public onResponseInterceptorChanged: ResponseInterceptorChangedDelegate;
  public onErrorInterceptorChanged: ResponseInterceptorChangedDelegate;
}
Factory.RegisterExplicit("sharedInterceptors", sharedInterceptors);

export class baseRestService {

  protected allwaysSendAuthenticationToken: boolean = true;
  protected saveToSessionStorage: boolean = true;
  public baseUrl: string = "";
  private _interceptors: sharedInterceptors = null;

  set interceptors(value: interceptorsConfig) {
    for (const i in value.requestInterceptors) { this.http.interceptors.request.use(value.requestInterceptors[i]); }

    for (const i in value.responseInterceptors) { this.http.interceptors.response.use(value.responseInterceptors[i]); }

    for (const i in value.errorsInterceptors) { this.http.interceptors.response.use((r) => r, value.errorsInterceptors[i]); }
  }

  public OnError: OnErrorDelegate;
  public OnHeadersPreparing: OnHeadersPreparingDelegate;

  protected http: AxiosInstance;

  constructor() {
    this.http = Axios.create();
    this._interceptors = Factory.GetByName("sharedInterceptors");

    // Initialize preregistered interceptors
    for (const i in this._interceptors.requestInterceptors) { this.http.interceptors.request.use(this._interceptors.requestInterceptors[i]); }

    for (const i in this._interceptors.responseInterceptors) { this.http.interceptors.response.use(this._interceptors.responseInterceptors[i]); }

    for (const i in this._interceptors.errorsInterceptors) { this.http.interceptors.response.use((r) => r, this._interceptors.errorsInterceptors[i]); }

    this._interceptors.onRequestInterceptorChanged = (i) => this.http.interceptors.request.use(i);
    this._interceptors.onResponseInterceptorChanged = (i) => this.http.interceptors.response.use(i);
    this._interceptors.onErrorInterceptorChanged = (i) => this.http.interceptors.response.use(i);

    this.setArraySerializationMethod();
  }

  private setArraySerializationMethod() {
    this.http.interceptors.request.use(async (reqConfig) => {
      // change the default serializer only if the method is a GET
      if (reqConfig.method !== "get") {
        return reqConfig;
      }
      // the 'repeat' is the standard behavior for array: arrKey=x&arrKey=y&arrKey=z....
      reqConfig.paramsSerializer = (params) => {
        return Qs.stringify(params, { arrayFormat: "repeat" });
      };
      return reqConfig;
    });
  }

  protected async getRaw(uri: string, params: object = {}, sendAuthenticationToken: boolean = false): Promise<AxiosResponse> {
    AlertHelper.showLoader("loading...");

    const response = await this.http.get(this.baseUrl + uri,
      {
        headers: this.prepareHeaders(this.allwaysSendAuthenticationToken || sendAuthenticationToken, null),
        params,
      });

    AlertHelper.hideLoader();
    if (response.status !== 200 && this.OnError) { this.OnError(response); }
    return response;
  }

  protected async get(uri: string, params: object = {}, sendAuthenticationToken: boolean = false): Promise<AxiosResponse> {
    AlertHelper.showLoader("loading...");

    const response = await this.http.get(this.baseUrl + uri,
      {
        headers: this.prepareHeaders(this.allwaysSendAuthenticationToken || sendAuthenticationToken, "application/json"),
        params,
      });

    AlertHelper.hideLoader();
    if (response.status !== 200 && this.OnError) { this.OnError(response); }
    return response;
  }

  protected async Get<TResult>(uri: string, params: object = {}, sendAuthenticationToken: boolean = false): Promise<TResult> {
    const result = await this.get(uri, params, sendAuthenticationToken);
    if (result.status === 200) {
      return result.data as TResult;
    } else if (this.OnError) { this.OnError(result); }
    return null;
    
    // var d = new Deferred<TResult>();
    // setTimeout(() => {
    //   this.get(uri, params, sendAuthenticationToken).then((result) => {
    //     if (result.status === 200) {
    //       d.resolve(result.data);
    //     } else if (this.OnError) {
    //       d.reject(null);
    //     }
    //   });
    // }, uri.startsWith('https://aidsinfodbapi.unaids.org') ? 500 : 0)
    // return d.promise;
  }

  protected async post(uri: string, data: any, params: object = {}, sendAuthenticationToken: boolean = false, contentType: string = "application/json"): Promise<AxiosResponse> {
    let _body = unjoin(data);
    if (contentType == "application/x-www-form-urlencoded")
      _body = QueryString.stringify(_body)

    try {
      const response = await this.http.post(this.baseUrl + uri, _body,
        {
          headers: this.prepareHeaders(this.allwaysSendAuthenticationToken || sendAuthenticationToken, contentType),
          params,

        } as AxiosRequestConfig);

      return response;
    } catch (err) {
      if (this.OnError) {
        this.OnError(err);
      }
      return err.response;
    }

  }

  protected async Post<TResult>(uri: string, data: any, params: object = {}, sendAuthenticationToken: boolean = false, contentType: string = "application/json"): Promise<TResult> {
    const result = await this.post(uri, data, params, sendAuthenticationToken, contentType);
    if (result.status === 200) {
      return result.data as TResult;
    } else {
      if (this.OnError) { this.OnError(result); }
      AlertHelper.showError("an_error_has_occurred", result.data.message, 5000);
    }

    return null;
  }

  protected async put(uri: string, data: any, params: object = {}, sendAuthenticationToken: boolean = false, contentType: string = "application/json"): Promise<AxiosResponse> {
    const response = await this.http.put(this.baseUrl + uri, unjoin(data),
      {
        headers: this.prepareHeaders(this.allwaysSendAuthenticationToken || sendAuthenticationToken, contentType),
        params,
      });
    if (response.status !== 200 && this.OnError) { this.OnError(response); }
    return response;
  }

  protected async Put<TResult>(uri: string, data: any, params: object = {}, sendAuthenticationToken: boolean = false, contentType: string = "application/json"): Promise<TResult> {
    const result = await this.put(uri, data, params, sendAuthenticationToken, contentType);
    if (result.status === 200) {
      return result.data as TResult;
    } else if (this.OnError) { this.OnError(result); }
    return null;
  }

  protected async delete(uri: string, params: object = {}, sendAuthenticationToken: boolean = false): Promise<AxiosResponse> {
    const response = await this.http.delete(this.baseUrl + uri,
      {
        headers: this.prepareHeaders(this.allwaysSendAuthenticationToken || sendAuthenticationToken, null),
        params,
      });
    if (response.status !== 200 && this.OnError) { this.OnError(response); }
    return response;
  }
  protected async Delete<TResult>(uri: string, params: object = {}, sendAuthenticationToken: boolean = false): Promise<TResult> {
    const result = await this.delete(uri, params, sendAuthenticationToken);
    if (result.status === 200) {
      return result.data as TResult;
    } else if (this.OnError) { this.OnError(result); }
    return null;
  }

  protected async downloadGet(uri: string, params: object = {}, sendAuthenticationToken: boolean = false): Promise<AxiosResponse> {
    const response = await this.http.get(this.baseUrl + uri, {
      headers: this.prepareHeaders(this.allwaysSendAuthenticationToken || sendAuthenticationToken, null),
      params,
      responseType: "arraybuffer",
    });
    if (response.status !== 200 && this.OnError) { this.OnError(response); }
    return response;
  }

  protected async downloadPost(uri: string, data?: any, params: object = {}, sendAuthenticationToken: boolean = false): Promise<AxiosResponse> {
    const response = await this.http.post(this.baseUrl + uri, unjoin(data), {
      headers: this.prepareHeaders(this.allwaysSendAuthenticationToken || sendAuthenticationToken, null),
      params,
      responseType: "arraybuffer",
    });
    if (response.status !== 200 && this.OnError) { this.OnError(response); }
    return response;
  }

  protected prepareHeaders(auth: boolean = false, contentType: string): any {

    const headers: any = {};

    if (auth) {
      const authData = this.getAuthenticationToken();
      if (authData) {
        headers["Authorization"] = "Bearer " + authData.access_token;
      }
    }
    if (contentType)
      headers["Content-Type"] = contentType;

    if (this.OnHeadersPreparing) { this.OnHeadersPreparing(headers); }
    return headers;
  }

  protected getAuthenticationToken(): AuthToken {
    // let wstore = window.sessionStorage;
    // let sstore = window.localStorage;
    // return JSON.parse(wstore.getItem("authorizationData") || sstore.getItem("authorizationData") || null) as AuthToken
    return baseRestService.getAuthenticationToken();
  }

  public static getAuthenticationToken(): AuthToken {
    const wstore = window.sessionStorage;
    const sstore = window.localStorage;
    return JSON.parse(wstore.getItem("authorizationData") || sstore.getItem("authorizationData") || null) as AuthToken;
  }

  protected setAuthenticationToken(data: AuthToken) {
    const storage: any = this.saveToSessionStorage ? window.sessionStorage : window.localStorage;
    storage.setItem("authorizationData", JSON.stringify(data));
  }

  protected deleteAuthenticationToken() {
    window.sessionStorage.removeItem("authorizationData");
    window.localStorage.removeItem("authorizationData");
  }

  private validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }
}

export type OnErrorDelegate = (data: DataResponse) => void;
type OnHeadersPreparingDelegate = (headers: Headers) => void;

export class AuthToken {
  public access_token: string;
  public refresh_token: string;
  public id_token: string;
  public expires_in: number;
  public expiration_date: number;
  public resource: string;
  public userName: string;
  public token_type: string;
}

export class DataResponse {
  public status: number;
  public statusText: string;
  public data: any;
}
