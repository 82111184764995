import * as el from "esri-loader";
import Vue, { WatchOptions } from "vue";
import { ArrayObserver } from "./Observers/ObservableArray";
import { Esri } from "./Esri";

declare let window: any;

export class Loader {
  public static isloading: Promise<any>;

  public static packageName: string = "esri";

  public static remapPrefix(prefix: string, uri: string) {
    Loader.packageName = prefix;

    window.dojoConfig = {
      async: true,
      packages: [{ name: prefix, location: uri }],
      has: { "dojo-preload-i18n-Api": false },
    };
  }

  public static initialize(url: string = "https://js.arcgis.com/4.19/") {
    if (!this.isloading) {
      this.isloading = new Promise((resolve, reject) => {
        if (!el.isLoaded()) {
          el.loadScript({ url }).then((value) => { resolve(value); }).catch((err) => { throw (err); });
        }
      });
    }
  }

  public static async get(dep: string): Promise<any> {
    await this.isloading;
    return new Promise<any>((resolve, reject) => {
      el.loadModules([dep]).then((refs) => {
        resolve(refs[0]);
      });
    });
  }

  public static async load(...deps: string[]): Promise<any> {
    await this.isloading;
    return new Promise((resolve, reject) => {
      el.loadModules(deps).then((refs) => {
        resolve(refs);
      });
    });
  }

  public static async create<T>(dep: string, options?: any): Promise<T> {

    const ctor: any = await Loader.get(dep);
    if (!ctor) { throw new Error("Unable to create :" + dep); }

    return (await new CreationResult<T>(new ctor(options))).result;
  }
}

export class CreationResult<T> {
  public result: T;

  constructor(item: T) {
    this.result = item;
  }
}

export function BindTo(vue: Vue, obj: any, propname: string, twoWay: boolean = false, esriprop?: string, options?: WatchOptions) {
  vue.$watch(propname, (n, o) => {
    if (n !== o) {
      obj.set(esriprop || propname, n, options);
    }
  });

  if (twoWay && obj.watch) {
    obj.watch(esriprop || propname, (newvalue, old) => {
      if (newvalue !== old) {
        vue.$set(vue, propname, newvalue);
      }
    });
  }
}

export async function BindArrayToLayer<T>(from: T[], to: __esri.Collection<__esri.Graphic>, map: (item: T) => Promise<__esri.Graphic>, deep: boolean = false, reobserve: boolean = false, immediate: boolean = false) {
  function removeGraphic(_g) {
    const $g = _g._$graphic$_;
    // let idx = to.indexOf($g);
    // if (idx >= 0) (to as any).splice(idx, 1);
    to.remove($g);
    _g._$graphic$_ = null;
  }

  async function addGraphic(_g) {
    if ((_g as any)._$graphic$_) { return; }
    const $g = (await map(_g)) as __esri.Graphic;
    (_g as any)._$graphic$_ = $g;
    to.add($g);
  }

  async function changed(_g) {
    const old = (_g as any)._$graphic$_;
    const $g = (await map(_g)) as __esri.Graphic;
    (_g as any)._$graphic$_ = $g;
    if (old) {
      to.remove(old);
    }
    to.add($g);
  }

  if ((from as any).__unobserve && reobserve) {
    (from as any).__unobserve();
  }

  const observer = new ArrayObserver<T>(from, deep, reobserve);
  (from as any).__unobserve = observer.Subscribe((operation, inserted, deleted) => {
    if (operation === "changed") {
      inserted.forEach(async (i) => await changed(i));
      return;
    }

    // tslint:disable-next-line:curly
    if (deleted)
      deleted.forEach((i: any) => {
        if (i instanceof Array) {
          i.forEach((element) => removeGraphic(element));
        }
        removeGraphic(i);
      });

    if (inserted) {
      inserted.forEach(async (i, idx) => {
        await addGraphic(i);
      });
    }
  });

  if (immediate) {
    (from).forEach(async (i) => {
      await addGraphic(i);
    });
  }
}
