import { CreateActionsHandler, CreateGettersHandler } from "./UTILS";

export interface ConfigurationsStoreModel {
  configurations: KpAtlas.Configurations;
  annex1: KpAtlas.IndicatorGroup[];
  countriesPriority: KpAtlas.CountriesPriority[];

  jhuConfigurations: JHU.Configuration[],
  jhuCountriesMapper: JHU.CountryMapper[],

  selectedIndicatorGroup: KpAtlas.IndicatorGroup;
  selectedIndicators: Array<KpAtlas.Indicator | JHU.Indicator>;
  compare: boolean;
  summaryPriorityIndicators: { kp1: { group: KpAtlas.IndicatorGroup, indicators: Array<KpAtlas.Indicator | JHU.Indicator> }, kp2: { group: KpAtlas.IndicatorGroup, indicators: Array<KpAtlas.Indicator | JHU.Indicator> } };
}

interface ConfigurationsStoreGetters {
  config(): KpAtlas.Configurations;
  annex1(): KpAtlas.IndicatorGroup[];
  countriesPriority(): KpAtlas.CountriesPriority[];
  jhuConfigurations(): JHU.Configuration[];
  jhuCountriesMapper(): JHU.CountryMapper[];
  jhuCountry(code: string): JHU.CountryMapper;
  selectedIndicatorGroup(): KpAtlas.IndicatorGroup;
  selectedIndicators(): Array<KpAtlas.Indicator | JHU.Indicator>;
  compare(): boolean;
  summaryPriorityIndicators(): { kp1: { group: KpAtlas.IndicatorGroup, indicators: Array<KpAtlas.Indicator | JHU.Indicator> }, kp2: { group: KpAtlas.IndicatorGroup, indicators: Array<KpAtlas.Indicator | JHU.Indicator> } };
}
interface ConfigurationsStoreActions {
  setConfiguration(data: KpAtlas.Configurations);
  cleanConfiguration();
  setAnnex1(data: KpAtlas.IndicatorGroup[]);
  cleanAnnex1();
  setCountriesPriority(data: KpAtlas.CountriesPriority[]);
  cleanCountriesPriority();

  setJHUConfigurations(data: JHU.Configuration[]);
  cleanJHUConfigurations();
  setJHUCountriesMapper(data: JHU.CountryMapper[]);
  cleanJHUCountriesMapper();

  selectIndicatorGroup(data: KpAtlas.IndicatorGroup);
  cleanIndicatorGroup();
  selectIndicator(data: KpAtlas.Indicator | JHU.Indicator);
  cleanIndicator();
  setCompare(data: boolean);
  setSummaryPriorityIndicators(data: { kpPriority: string, group: KpAtlas.IndicatorGroup, indicators: Array<KpAtlas.Indicator | JHU.Indicator> });
}

export const configurationsStore = {
  PREFIX: "configurations",
  namespaced: true,
  state: {
    configurations: null,
    annex1: [],
    countriesPriority: [],
    jhuConfigurations: [],
    jhuCountriesMapper: [],
    selectedIndicatorGroup: null,
    selectedIndicators: [],
    compare: false,
    summaryPriorityIndicators: { kp1: { group: null, indicators: [] }, kp2: { group: null, indicators: [] } },
  } as ConfigurationsStoreModel,
  getters: {
    config: (state: ConfigurationsStoreModel) => () => {
      return state.configurations;
    },
    annex1: (state: ConfigurationsStoreModel) => () => {
      return state.annex1;
    },
    countriesPriority: (state: ConfigurationsStoreModel) => () => {
      return state.countriesPriority;
    },
    jhuConfigurations: (state: ConfigurationsStoreModel) => () => {
      return state.jhuConfigurations;
    },
    jhuCountriesMapper: (state: ConfigurationsStoreModel) => () => {
      return state.jhuCountriesMapper;
    },
    jhuCountry: (state: ConfigurationsStoreModel) => (code: string) => {
      return state.jhuCountriesMapper.find(f => f.countryId.toString() === code);
    },
    selectedIndicatorGroup: (state: ConfigurationsStoreModel) => () => {
      return state.selectedIndicatorGroup;
    },
    selectedIndicators: (state: ConfigurationsStoreModel) => () => {
      return state.selectedIndicators;
    },
    compare: (state: ConfigurationsStoreModel) => () => {
      return state.compare;
    },
    summaryPriorityIndicators: (state: ConfigurationsStoreModel) => () => {
      return state.summaryPriorityIndicators;
    },
  },
  mutations: {
    setConfiguration(state: ConfigurationsStoreModel, data: KpAtlas.Configurations) {
      if (data) {
        state.configurations = data;
      }
    },
    cleanConfiguration(state: ConfigurationsStoreModel) {
      state.configurations = null;
    },
    setAnnex1(state: ConfigurationsStoreModel, data: KpAtlas.IndicatorGroup[]) {
      if (data) {
        state.annex1 = data.map(m => {
          m.indicators = m.indicators.map(mm => {
            mm.sourceData = "KPATLAS";
            return mm;
          })
          return m;
        });
      }
    },
    cleanAnnex1(state: ConfigurationsStoreModel) {
      state.annex1 = [];
    },
    setJHUConfigurations(state: ConfigurationsStoreModel, data: JHU.Configuration[]) {
      if (data && data.length > 0) {
        state.jhuConfigurations = data;
        for (const key in data) {
          const element = data[key];
          const matchWithIndicatorUNAIDS = state.annex1.find(f => f.name === element.name);
          if (matchWithIndicatorUNAIDS) {
            matchWithIndicatorUNAIDS.indicators = matchWithIndicatorUNAIDS.indicators.concat(
              element.indicators.filter(asfa =>(asfa.blocks || []).length <= 0 || (asfa.countryField && asfa.countryField.source && asfa.timeTrendField && asfa.timeTrendField.source)).map(m => {
                m.sourceData = "JHU";
                return m;
              }))
          }
        }
      }
    },
    cleanJHUConfigurations(state: ConfigurationsStoreModel) {
      state.jhuConfigurations = null;
    },
    setCountriesPriority(state: ConfigurationsStoreModel, data: KpAtlas.CountriesPriority[]) {
      if (data) {
        state.countriesPriority = data;
      }
    },
    cleanCountriesPriority(state: ConfigurationsStoreModel) {
      state.countriesPriority = null;
    },


    setJHUCountriesMapper(state: ConfigurationsStoreModel, data: JHU.CountryMapper[]) {
      if (data) {
        state.jhuCountriesMapper = data;
      }
    },
    cleanJHUCountriesMapper(state: ConfigurationsStoreModel) {
      state.jhuCountriesMapper = null;
    },

    selectIndicatorGroup(state: ConfigurationsStoreModel, data: KpAtlas.IndicatorGroup) {
      state.selectedIndicatorGroup = data;
    },
    cleanIndicatorGroup(state: ConfigurationsStoreModel) {
      state.selectedIndicatorGroup = null;
    },
    selectIndicator(state: ConfigurationsStoreModel, data: KpAtlas.Indicator | JHU.Indicator) {
      if (!state.compare || (state.compare && state.selectedIndicators.length > 1)) {
        state.selectedIndicators.pop();
      }
      state.selectedIndicators.push(data);
    },
    cleanIndicator(state: ConfigurationsStoreModel) {
      state.selectedIndicators = [];
    },
    setCompare(state: ConfigurationsStoreModel, data: boolean) {
      state.compare = data;
      if (!state.compare && state.selectedIndicators.length > 1) {
        state.selectedIndicators.pop();
      }
    },
    setSummaryPriorityIndicators(state: ConfigurationsStoreModel, data: { kpPriority: string, group: KpAtlas.IndicatorGroup, indicators: Array<KpAtlas.Indicator | JHU.Indicator> }) {
      state.summaryPriorityIndicators[data.kpPriority] = { group: data.group, indicators: data.indicators };
    },
  },
  actions: {
    setConfiguration(context, data: KpAtlas.Configurations) {
      context.commit("setConfiguration", data);
    },
    cleanConfiguration(context) {
      context.commit("cleanConfiguration");
    },
    setAnnex1(context, data: KpAtlas.IndicatorGroup[]) {
      context.commit("setAnnex1", data);
    },
    cleanAnnex1(context) {
      context.commit("cleanAnnex1");
    },
    setCountriesPriority(context, data: KpAtlas.CountriesPriority[]) {
      context.commit("setCountriesPriority", data);
    },
    cleanCountriesPriority(context) {
      context.commit("cleanCountriesPriority");
    },
    setJHUConfigurations(context, data: JHU.Configuration[]) {
      context.commit("setJHUConfigurations", data);
    },
    cleanJHUConfigurations(context) {
      context.commit("cleanJHUConfigurations");
    },
    setJHUCountriesMapper(context, data: JHU.CountryMapper[]) {
      context.commit("setJHUCountriesMapper", data);
    },
    cleanJHUCountriesMapper(context) {
      context.commit("cleanJHUCountriesMapper");
    },
    selectIndicatorGroup(context, data: KpAtlas.IndicatorGroup) {
      context.commit("selectIndicatorGroup", data);
    },
    cleanIndicatorGroup(context) {
      context.commit("cleanIndicatorGroup");
    },
    selectIndicator(context, data: KpAtlas.Indicator | JHU.Indicator) {
      context.commit("selectIndicator", data);
    },
    cleanIndicator(context) {
      context.commit("cleanIndicator");
    },
    setCompare(context, data: boolean) {
      context.commit("setCompare", data);
    },
    setSummaryPriorityIndicators(context, data: { kpPriority: string, group: KpAtlas.IndicatorGroup, indicators: Array<KpAtlas.Indicator | JHU.Indicator> }) {
      context.commit("setSummaryPriorityIndicators", data);
    },
  },
};

export const configurationsStoreActions = CreateActionsHandler<ConfigurationsStoreActions>(configurationsStore);
export const configurationsStoreGetters = CreateGettersHandler<ConfigurationsStoreGetters>(configurationsStore);
