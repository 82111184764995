import Vue from "vue";
import Vuex from "vuex";

import { uiStore } from "./ui.store";
import { geometriesStore, GeometriesStoreModel} from "./geometries.store";
import { configurationsStore, ConfigurationsStoreModel } from "./configurations.store";
import { kpatlasStore, KpatlasStoreModel } from "./kpatlas.store";

Vue.use(Vuex);

export interface AppModel {
  configurations: ConfigurationsStoreModel;
  geometries: GeometriesStoreModel;
  kpatlas: KpatlasStoreModel;
}

const appStore = {
  modules: {
    [uiStore.PREFIX]: uiStore,
    [configurationsStore.PREFIX]: configurationsStore,
    [geometriesStore.PREFIX]: geometriesStore,
    [kpatlasStore.PREFIX]: kpatlasStore,
  },
};

export const store = new Vuex.Store(appStore as any);

export const getStoreState = (): AppModel => {
  return store.state as AppModel;
};
