import moment from "moment";

export function dateformat(value, format) {
  let date;
  if (!value) { return ""; }

  if (value instanceof Date) {
    date = moment(value);
  } else {
    date = moment.utc(String(value), (moment as any).HTML5_FMT.DATETIME_LOCAL_MS).local();
    if (!date.isValid()) {
      console.debug("Date format is invalid", { originalValue: value, formatRequired: format, formatValue: date });
      return "";
    }
  }
  return date.format(format);
}

export function formatNumber(num: number, digits: number): string {
  if (num === undefined || num === null) { return null; }

  if (typeof (num) === "string") {
    num = parseFloat(num);
  }

  const si = [
    { value: 1e18, symbol: "E" },
    { value: 1e15, symbol: "P" },
    { value: 1e12, symbol: "T" },
    { value: 1e9, symbol: "B" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "K" },
  ];

  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      const parsed = (num / si[i].value);
      return parsed.toFixed(1).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[i].symbol;
    }
  }

  const decimalCount = Math.min(decimalsCountrer(num), 2);
  return num.toFixed(decimalCount);
}

function decimalsCountrer(value) {
  if (Math.floor(value) === value) {
    return 0;
  }
  return value.toString().split(".")[1].length || 0;
}
