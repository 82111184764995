
import { baseRestService } from "./_base";
import { Utils } from "@/utils";
import { configurationsStoreGetters, kpatlasStoreActions } from "@/store";
import { Deferred } from "./_base/Deferred";

class JHURedcupService extends baseRestService {
  public language: string = "en";
  public responseFormat: string = "JSON";
  public timePeriod: string = "MRD";

  constructor() {
    super();
  }

  public async CountriesDatas(indicator: JHU.Indicator, blocks: JHU.Block[], areas: string[], recalcLegend: boolean = false): Promise<KpAtlas.ResponseIndicatorBlock[]> {
    var params = {};
    params["token"] = indicator.token;
    params["report_id"] = indicator.reportId;
    params["content"] = "report";
    params["format"] = "json";
    params["rawOrLabel"] = "raw";
    params["rawOrLabelHeaders"] = "raw";
    params["returnFormat"] = "json";

    this.baseUrl = indicator.baseUrl;
    const queryData = await this.Post<any[]>(indicator.relativeUrl, params, params, false, "application/x-www-form-urlencoded") || [];

    if (queryData.length <= 0)
      return queryData;

    let results: KpAtlas.ResponseIndicatorBlockParam[] = [];
    for (let i = 0; i < blocks.length; i++) {
      const block = blocks[i];
      for (let j = 0; j < block.params.length; j++) {
        const param = block.params[j];
        results.push(this.closureCountriesDatas(i, areas, indicator, block, param, recalcLegend)(queryData.filter(f => f[param.source])));
      }
    }

    if (results.length <= 0) return [];
    return Utils.ParamReduce(results)
  }

  private closureCountriesDatas(index: number, areas: string[], indicator: JHU.Indicator, block: JHU.Block, param: JHU.Param, recalcLegend: boolean): (value: any[]) => KpAtlas.ResponseIndicatorBlockParam {
    return (result: any[]): KpAtlas.ResponseIndicatorBlockParam => {
      let datas = this.observationsReduce(result, areas, indicator, block, param);
      if (index === 0) {
        if (datas && datas.length > 0) {
          datas = datas.sort((a, b) => {
            if (Number(a.lastValue) < Number(b.lastValue)) { return -1; }
            if (Number(a.lastValue) > Number(b.lastValue)) { return 1; }
            return 0;
          });
        }
      }

      if (recalcLegend) {
        Utils.CleanLegenda();
        const legend = Utils.MakeLegenda(param as KpAtlas.BlockParameter, datas, configurationsStoreGetters.config().legend.countryColorClassifications, indicator.breaks, (d: KpAtlas.Response) => Number(d.lastValue));
        kpatlasStoreActions.setLegend(legend);
      }

      return {
        indicator: indicator as any,
        block: block as any,
        param: param as any,
        result: datas,
      } as KpAtlas.ResponseIndicatorBlockParam;
    };
  }

  public paramReduce(data: KpAtlas.ResponseIndicatorBlockParam[]): KpAtlas.ResponseIndicatorBlock[] {
    const temp = {};
    const reduced = data.filter((f) => !!f).reduce((r, o) => {
      let key = `${o.indicator.key}`;
      if (o.block) {
        key += `_${o.block.label.replace(/ /gi, "-").toLowerCase()}`;
      }
      if (!temp[key]) {
        temp[key] = { indicator: o.indicator, block: o.block, groups: [o] } as KpAtlas.ResponseIndicatorBlock;
        r.push(temp[key]);
      } else {
        temp[key].groups.push(o);
      }
      return r;
    }, [] as KpAtlas.ResponseIndicatorBlock[]);
    return reduced;
  }

  public observationsReduce(data: any[], areas: string[], indicator: JHU.Indicator, block: JHU.Block, param: JHU.Param): KpAtlas.Response[] {
    const temp = {};
    const reduced = data.sort((a, b) => {
      if (a[indicator.timeTrendField.source] > b[indicator.timeTrendField.source]) { return -1; }
      if (a[indicator.timeTrendField.source] < b[indicator.timeTrendField.source]) { return 1; }
      return 0;
    }).reduce((r, o) => {
      const key = `${o[indicator.countryField.source]}`;
      const parsed = this.normalizeObservation(o, areas, indicator, block, param);
      if (parsed) {
        if (!temp[key]) {
          temp[key] = {
            iso3: parsed.AREA.id,
            indicatorKey: parsed.INDICATOR.id,
            indicatorName: Utils.capitalizeFirstLetter(parsed.INDICATOR.value),
            blockLabel: block ? block.label : null,
            paramKey: parsed.SUBGROUP.id,
            paramName: Utils.capitalizeFirstLetter(parsed.SUBGROUP.value),
            lastValue: parsed.VALUE,
            timePeriod: parsed.TIME_PERIOD,
            hasSubNationalData: false,
            nationWithSubNationalData: [],
            denominator: parsed.DENOMINATOR,
            unit: parsed.UNIT.id,
            footNotes: parsed.FOOTNOTES,
            isTextualData: parsed.IS_TEXTUALDATA,
            observations: [parsed],
          } as KpAtlas.Response;

          r.push(temp[key]);
        } else {
          temp[key].observations.push(parsed);
        }
      }
      return r;
    }, [] as KpAtlas.Response[]);
    return reduced;
  }

  public normalizeObservation(observation, areas: string[], indicator: JHU.Indicator, block: JHU.Block, param: JHU.Param): KpAtlas.NormalizeObservation {
    const countryFieldKey = indicator.countryField.source;
    let countryFieldValue = { id: observation[countryFieldKey], value: observation[countryFieldKey] };
    if (indicator.countryField.needDecode) {
      const decoded = this.decodeCountryId(observation[countryFieldKey]);
      if (!decoded) return null;
      countryFieldValue = { id: decoded.ISO3, value: decoded.countryName };
    }

    if (areas.indexOf(countryFieldValue.id) === -1)
      return null;

    const yearFieldValue = observation[indicator.timeTrendField.source] ? parseInt(observation[indicator.timeTrendField.source], 10) : 0;

    const footnoteFieldValue = observation[indicator.footnoteField.source] || null;

    return {
      INDICATOR: { id: indicator.key.toUpperCase(), value: indicator.name },
      SUBGROUP: { id: param.source.toUpperCase(), value: param.label },
      UNIT: { id: param.unit.toUpperCase(), value: Utils.capitalizeFirstLetter(param.unit) },
      AREA: countryFieldValue,
      TIME_PERIOD: yearFieldValue,
      FOOTNOTES: footnoteFieldValue,
      IS_TEXTUALDATA: false,
      DENOMINATOR: null,
      VALUE: param.unit == "percent" || param.unit == "number" ? Number(observation[param.source]) : observation[param.source]
    };
  }

  private decodeCountryId(code: string): JHU.CountryMapper {
    return configurationsStoreGetters.jhuCountry(code);
  }

}

export const jhuRedcupService = new JHURedcupService();
