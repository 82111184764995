import { store, AppModel, getStoreState } from "@/store/app.store";
import { join, propertyResolve, unjoin, fillArray } from "./UTILS";

import { uiStoreActions, uiStoreGetters } from "./ui.store";
import { configurationsStoreGetters, configurationsStoreActions } from "./configurations.store";
import { geometriesStoreActions, geometriesStoreGetters } from "./geometries.store";
import { kpatlasStoreActions, kpatlasStoreGetters } from "./kpatlas.store";

export {
  store,
  AppModel,
  join,
  fillArray,
  getStoreState,
  propertyResolve,
  unjoin,
  uiStoreActions,
  uiStoreGetters,
  configurationsStoreGetters,
  configurationsStoreActions,
  geometriesStoreActions,
  geometriesStoreGetters,
  kpatlasStoreActions,
  kpatlasStoreGetters,
};
