import { resolverConfiguration } from "@/store/UTILS";
import { geometriesService, configurationService } from "@/services";
import { AlertHelper } from "@/services/_base/AlertHelper";

export async function ServiceInizialization() {
    await configurationService.LoadAnnex1(),
    await configurationService.LoadCountriesPriority(),
    await configurationService.LoadJHUConfiguration(),
    await configurationService.LoadJHUCountriesMapper(),
    await configurationService.LoadConfiguration(),

    AlertHelper.hideLoader();
    resolverConfiguration.delayResolver = false;
}
