import Vue from "vue";
import VueRouter from "vue-router";
import { Component } from "vue-property-decorator";

import routes from "@/router/routes";

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteUpdate",
  "beforeRouteLeave",
]);

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 } as any),
  routes,
});

router.beforeEach(async (to, from, next) => {
    next();
});

export default router;
