
import { baseRestService } from "./_base";
import { Deferred } from "./_base/Deferred";
import { Utils } from "@/utils";
import { configurationsStoreGetters, kpatlasStoreActions } from "@/store";

class AidsInfoService extends baseRestService {
  public language: string = "en";
  public responseFormat: string = "JSON";
  public timePeriod: string = "MRD";

  private callStack: Map<string, { uri: string, params: any }[]> = null;
  constructor() {
    super();
    this.callStack = new Map();
    setInterval(() => this.CallsDelayed(), 500);
  }


  public async GetDatas(aidsInfoCallParameters: AidsInfoCall.Parameters, blockKey = new Date().getTime().toString(), take: number = 250): Promise<AidsInfoCall.Response> {
    const promises: Array<Promise<AidsInfoCall.Response>> = [];
    const d = new Deferred<AidsInfoCall.Response>();

    if (!aidsInfoCallParameters.areas || aidsInfoCallParameters.areas.constructor !== Array) {
      d.reject("Areas is undefined or is not Array. This call required that the Indicators and Areas are Array!");
      return d.promise;
    }

    this.callStack.set(blockKey, []);

    for (let idx = 0; idx < aidsInfoCallParameters.areas.length; idx = idx + take) {
      const params: any = { /// ATTENZIONE! NEL SERVIZIO DI AIDSINFOONLINE I PARAMETRI SONO CASE SENSITIVE
        p: 1,
        AREA: aidsInfoCallParameters.areas.slice(idx, Math.min(idx + take, aidsInfoCallParameters.areas.length)).join(","),
        INDICATOR: aidsInfoCallParameters.indicator,
        Language: this.language,
        ResponseFormat: this.responseFormat,
        SOURCE: aidsInfoCallParameters.source,
      };

      if (aidsInfoCallParameters.timePeriod) {
        params.TIME_PERIOD = aidsInfoCallParameters.timePeriod;
      }

      this.callStack.get(blockKey).push({
        uri: `${configurationsStoreGetters.config().baseUrl}/${aidsInfoCallParameters.relativeUrl}`,
        params: params
      });
    }

    var responses = await this.CallsDelayedQueue(blockKey)

    if (this.callStack.has(blockKey) && this.callStack.get(blockKey).length <= 0) {
      this.callStack.delete(blockKey);
    }

    const result: AidsInfoCall.Response = { Data: [{ lang: "", Observation: [] }] };
    for (const responseKey in responses) {
      if (responses.hasOwnProperty(responseKey)) {
        const response = responses[responseKey];
        // unaidsLocalStorage.setItem<AidsInfoCall.Response>(response["key"], response);
        if (response && response.Data && response.Data[0] && response.Data[0].lang && response.Data[0].Observation) {
          result.Data[0].lang = response.Data[0].lang;
          result.Data[0].Observation = result.Data[0].Observation.concat(response.Data[0].Observation);
        }
      }
    }
    d.resolve(result);

    return d.promise;
  }

  private callsDelayedQueuePromise: Map<string, Deferred<AidsInfoCall.Response[]>> = new Map();
  private callsDelayedQueueResults: Map<string, AidsInfoCall.Response[]> = new Map();

  public async CallsDelayedQueue(blockKey: string) {
    this.callsDelayedQueuePromise.set(blockKey, new Deferred<AidsInfoCall.Response[]>());
    this.callsDelayedQueueResults.set(blockKey, []);
    return this.callsDelayedQueuePromise.get(blockKey).promise;
  }

  public async CallsDelayed() {
    if (this.callStack && this.callStack.size > 0) {
      const delay = configurationsStoreGetters.config() ? configurationsStoreGetters.config().callDelay : 200;
      let calls = Array.from(this.callStack.entries()).filter(f => f[1].length > 0);
      while (calls.length > 0) {
        var callk = calls.shift();
        var blockKey = callk[0];
        var blockCalls = callk[1];
        while (blockCalls.length > 0) {
          var call = blockCalls.shift();
          const result = await this.Get<AidsInfoCall.Response>(call.uri, call.params);
          this.callsDelayedQueueResults.get(blockKey).push(result);

          var d = new Deferred();
          setTimeout(() => {
            d.resolve()
          }, delay);
          await d.promise;
        }
        this.callsDelayedQueuePromise.get(blockKey).resolve(this.callsDelayedQueueResults.get(blockKey));
      }
    }
  }

  public async CountriesDatas(indicator: KpAtlas.Indicator, blocks: KpAtlas.Block[], areas: string[], recalcLegend: boolean = false): Promise<KpAtlas.ResponseIndicatorBlock[]> {
    const results: Array<KpAtlas.ResponseIndicatorBlockParam> = [];
    for (let i = 0; i < blocks.length; i++) {
      const block = blocks[i];
      for (let j = 0; j < block.params.length; j++) {
        const param = block.params[j];
        const callParameters: AidsInfoCall.Parameters = {
          relativeUrl: indicator.relativeUrl,
          areas,
          indicator: `${param.key}@${param.unit}@${param.group}`,
          source: param.source,
          timePeriod: param.timePeriod,
        };

        const call = await this.GetDatas(callParameters);
        if (call) results.push(this.closureCountriesDatas(i, indicator, block, param, recalcLegend)(call))
      }
    }

    if (results && results.length > 0) {
      return Utils.ParamReduce(results);
    }
    return null;
  }

  private closureCountriesDatas(index: number, indicator: KpAtlas.Indicator, block: KpAtlas.Block, param: KpAtlas.BlockParameter, recalcLegend: boolean): (value: AidsInfoCall.Response) => KpAtlas.ResponseIndicatorBlockParam {
    return (result: AidsInfoCall.Response): KpAtlas.ResponseIndicatorBlockParam => {
      let datas = Utils.ObservationsReduce(result && result.Data && result.Data.length > 0 && result.Data[0].Observation ? result.Data[0].Observation : [], block, param);
      if (index === 0) {
        if (datas && datas.length > 0) {
          datas = datas.sort((a, b) => {
            if (Number(a.lastValue) < Number(b.lastValue)) { return -1; }
            if (Number(a.lastValue) > Number(b.lastValue)) { return 1; }
            return 0;
          });
        }

        if (recalcLegend) {
          Utils.CleanLegenda();
          if (block.unit !== "TEXT_CODED" && block.unit !== "YES_NO") {
            const legend = Utils.MakeLegenda(param, datas, configurationsStoreGetters.config().legend.countryColorClassifications, indicator.breaks, (d: KpAtlas.Response) => Number(d.lastValue));
            kpatlasStoreActions.setLegend(legend);
          } else {
            Utils.RecalcLegendaStaticCoded(datas, (d: KpAtlas.Response) => Number(d.lastValue));
          }
        }
      }
      return { indicator, block, param, result: datas } as KpAtlas.ResponseIndicatorBlockParam;
    };
  }

  public async SubnationDatas(indicator: KpAtlas.Indicator, params: KpAtlas.BlockParameter[], areas: string[], recalcLegend: boolean = false): Promise<KpAtlas.ResponseIndicatorBlock[]> {
    let results: Array<KpAtlas.ResponseIndicatorBlockParam> = [];
    const filteredParams = params.filter((f) => f.group !== "CATEGORY");

    for (let i = 0; i < filteredParams.length; i++) {
      const param = filteredParams[i];
      const callParameters: AidsInfoCall.Parameters = {
        relativeUrl: indicator.relativeUrl,
        areas,
        indicator: `${param.key}@${param.unit}@${param.group}`,
        source: param.source,
        timePeriod: param.timePeriod,
      };

      const call = await this.GetDatas(callParameters);
      if (call) results.push(this.closureSubnations(i, indicator, param, recalcLegend)(call));
    }

    if (results && results.length > 0) {
      const areaIdsWithData = results.map((m) => m.result.map((mm) => mm.iso3)).reduce((accumulator, currentValue) => accumulator.concat(currentValue));
      if (areaIdsWithData && areaIdsWithData.length > 0) {
        const areasWithoutData = areas.filter((x) => !areaIdsWithData.includes(x)).concat(areaIdsWithData.filter(x => !areas.includes(x)));
        if (areasWithoutData.length > 0) {
          const category = params.find((f) => f.group === "CATEGORY");
          let categoryDatas: KpAtlas.ResponseIndicatorBlockParam = null;
          if (category) {
            for (let idx = 1; idx <= category.number; idx++) {
              const key = `${category.key}@${category.unit}@${category.group}${idx}`;
              const categoryParameters = {
                relativeUrl: indicator.relativeUrl,
                areas: areasWithoutData,
                indicator: key,
                source: category.source,
                timePeriod: category.timePeriod,
              } as AidsInfoCall.Parameters;
              const call = await this.GetDatas(categoryParameters);
              if (call) {
                categoryDatas = this.closureSubnations(idx, indicator, category, false)(call);
                break;
              }
            }
            if (categoryDatas !== null) {
              results = results.concat(categoryDatas);
            }
          }
        }
      }

      return Utils.ParamReduce(results);
    }
    return null;
  }

  private closureSubnations(index: number, indicator: KpAtlas.Indicator, param: KpAtlas.BlockParameter, recalcLegend: boolean): (value: AidsInfoCall.Response) => KpAtlas.ResponseIndicatorBlockParam {
    return (result: AidsInfoCall.Response): KpAtlas.ResponseIndicatorBlockParam => {
      let datas = Utils.ObservationsReduce(result && result.Data && result.Data.length > 0 && result.Data[0].Observation ? result.Data[0].Observation : [], null, param);
      if (index === 0) {
        if (datas && datas.length > 0) {
          datas = datas.sort((a, b) => {
            if (Number(a.lastValue) < Number(b.lastValue)) { return -1; }
            if (Number(a.lastValue) > Number(b.lastValue)) { return 1; }
            return 0;
          });
        }

        if (recalcLegend) {
          Utils.CleanLegenda();
          if (param.unit !== "TEXT_CODED" && param.unit !== "YES_NO") {
            const legend = Utils.MakeLegenda(param, datas, configurationsStoreGetters.config().legend.regionColorClassifications, indicator.breaks, (d: KpAtlas.Response) => Number(d.lastValue));
            kpatlasStoreActions.setLegend(legend);
          } else {
            Utils.RecalcLegendaStaticCoded(datas, (d: KpAtlas.Response) => Number(d.lastValue));
          }
        }
      }
      return { indicator, param, result: datas } as KpAtlas.ResponseIndicatorBlockParam;
    };
  }

  public Quality(indicator: KpAtlas.Indicator, quality: KpAtlas.BlockParameter, iso3) {
    const d = new Deferred();

    if (!quality || (Object.keys(quality).length === 0 && quality.constructor === Object)) {
      d.reject("Quality is undefined");
      return d.promise;
    }

    if (quality.constructor !== Object) {
      d.reject("Quality is not Object.");
      return d.promise;
    }

    const callParameters: AidsInfoCall.Parameters = {
      relativeUrl: indicator.relativeUrl,
      areas: [iso3],
      indicator: `${quality.key}@${quality.unit}@${quality.group}`,
      source: quality.source,
      timePeriod: "MRD",
    };

    this.GetDatas(callParameters).then((result) => {
      let datas = null;
      if (result && result.Data[0] && result.Data[0].Observation && result.Data[0].Observation.length > 0) {
        datas = result.Data[0].Observation[0][quality.field];
      }
      d.resolve(datas);
    }, (error) => {
      d.reject(error);
    });

    return d.promise;
  }
}

export const aidsInfoService = new AidsInfoService();
